import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { Field, Formik, useFormikContext } from "formik";
import { Text } from "../Text";
import { pxToRem } from "../../utils/px-to-rem";
import * as Yup from "yup";
import { enterTextViaScrambling } from "../../utils/text-animations";

interface ISingleInput {
    index: number;
    value: string;
    onChange: Function;
    hasError: boolean | string;
    hasErrorImmediate: boolean | string;
    hasSuccess: boolean;
    nextElRef?: React.RefObject<HTMLInputElement>;
    onIsValid?: () => void;
}

const FITB_VALUES = ["S", "W", "T", "W", "H", "R", "F", "N", "T", "H", "M"];

const SingleInput = React.forwardRef<HTMLInputElement, ISingleInput>(
    (
        {
            index,
            value,
            onChange,
            hasErrorImmediate,
            hasError,
            hasSuccess,
            nextElRef,
            onIsValid
        },
        ref
    ) => {
        const borderColor = hasError ? "red" : hasSuccess ? "green" : "white";

        // hook to focus on next input
        useEffect(() => {
            if (hasSuccess) {
                if (index < FITB_VALUES.length - 1) {
                    const nextEl = document.querySelector<HTMLInputElement>(
                        "input[name=fitb" + (index + 2) + "]"
                    );
                    if (nextEl) nextEl.focus();
                }
            }
        }, [hasSuccess]);

        return (
            <Field
                innerRef={ref}
                as="input"
                autocomplete="off"
                css={(theme) => ({
                    all: "unset",
                    background: "transparent",
                    border: "none",
                    boxShadow: "none",
                    borderBottom: `${pxToRem(8)} solid ${borderColor}`,
                    width: `calc(${theme.typography.fitb.fontSize} * 0.6)`,
                    ...theme.typography.fitb
                })}
                className={`fitb-input fitb-input-${index}`}
                type="text"
                name={`fitb${index + 1}`}
                value={value}
                onChange={(e) => {
                    const modifiedE = e;
                    modifiedE.target.value = modifiedE.target.value.charAt(0);
                    onChange(modifiedE);
                    // console.log('hasErrorImmediate', hasErrorImmediate, modifiedE.target.value, nextElRef);
                    // if (modifiedE.target.value.length > 0 && !hasErrorImmediate && nextElRef.current) {
                    //     nextElRef.current.focus();
                    // }
                }}
            />
        );
    }
);

const FitbSchema = Yup.object().shape({
    fitb1: Yup.string()
        .matches(
            new RegExp(`[${FITB_VALUES[0]}${FITB_VALUES[0].toLowerCase()}]`),
            ""
        )
        .required("Required"),
    fitb2: Yup.string()
        .matches(
            new RegExp(`[${FITB_VALUES[1]}${FITB_VALUES[1].toLowerCase()}]`),
            ""
        )
        .required("Required"),
    fitb3: Yup.string()
        .matches(
            new RegExp(`[${FITB_VALUES[2]}${FITB_VALUES[2].toLowerCase()}]`),
            ""
        )
        .required("Required"),
    fitb4: Yup.string()
        .matches(
            new RegExp(`[${FITB_VALUES[3]}${FITB_VALUES[3].toLowerCase()}]`),
            ""
        )
        .required("Required"),
    fitb5: Yup.string()
        .matches(
            new RegExp(`[${FITB_VALUES[4]}${FITB_VALUES[4].toLowerCase()}]`),
            ""
        )
        .required("Required"),
    fitb6: Yup.string()
        .matches(
            new RegExp(`[${FITB_VALUES[5]}${FITB_VALUES[5].toLowerCase()}]`),
            ""
        )
        .required("Required"),
    fitb7: Yup.string()
        .matches(
            new RegExp(`[${FITB_VALUES[6]}${FITB_VALUES[6].toLowerCase()}]`),
            ""
        )
        .required("Required"),
    fitb8: Yup.string()
        .matches(
            new RegExp(`[${FITB_VALUES[7]}${FITB_VALUES[7].toLowerCase()}]`),
            ""
        )
        .required("Required"),
    fitb9: Yup.string()
        .matches(
            new RegExp(`[${FITB_VALUES[8]}${FITB_VALUES[8].toLowerCase()}]`),
            ""
        )
        .required("Required"),
    fitb10: Yup.string()
        .matches(
            new RegExp(`[${FITB_VALUES[9]}${FITB_VALUES[9].toLowerCase()}]`),
            ""
        )
        .required("Required"),
    fitb11: Yup.string()
        .matches(
            new RegExp(`[${FITB_VALUES[10]}${FITB_VALUES[10].toLowerCase()}]`),
            ""
        )
        .required("Required")
});

export const FITB = ({ onComplete }: { onComplete: () => void }) => {
    const mainRef = useRef(null);
    const formRef = useRef(null);
    const fitb1Ref = useRef(null);
    const fitb2Ref = useRef(null);
    const fitb3Ref = useRef(null);
    const fitb4Ref = useRef(null);
    const fitb5Ref = useRef(null);
    const fitb6Ref = useRef(null);
    const fitb7Ref = useRef(null);
    const fitb8Ref = useRef(null);
    const fitb9Ref = useRef(null);
    const fitb10Ref = useRef(null);
    const fitb11Ref = useRef(null);

    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (isValid) {
            onComplete();
        }
    }, [isValid]);

    useLayoutEffect(() => {
        // For backspace/delete
        const onKeyDown = function (event) {
            const key = event.key; // const {key} = event; ES6+
            if (
                (key === "Backspace" || key === "Delete") &&
                document.activeElement.matches("input")
            ) {
                const activeInput = document.activeElement as HTMLInputElement;
                const value = activeInput.value;
                const name = activeInput.name;
                if (!value.length && name !== "fitb1") {
                    event.preventDefault();
                    const index = parseInt(name.split("fitb")[1], 10);
                    const prevInput = document.querySelector<HTMLInputElement>(
                        "input[name=fitb" + (index - 1) + "]"
                    );

                    formRef.current.setFieldValue(prevInput.name, "");
                    prevInput.focus();
                }
            }
        };
        const ctx = gsap.context(() => {
            const inputs = gsap.utils.toArray<HTMLInputElement>("input");
            inputs[0].focus();

            gsap.timeline({}).add(
                enterTextViaScrambling(
                    document.querySelector("#fitb-container")
                )
                    .play()
                    .to("#fitb-blanks span", {
                        visibility: "visible",
                        stagger: 0.05,
                        ease: "none"
                    })
            );

            document.addEventListener("keydown", onKeyDown);

            setTimeout(() => {
                gsap.to(".solve-button", {
                    autoAlpha: 1
                });
            }, 7000);
        }, mainRef); // <- IMPORTANT! Scopes selector text

        return () => {
            document.removeEventListener("keydown", onKeyDown);
            ctx.revert();
        };
    }, []);

    const solve = () => {
        const ctx = gsap.context(() => {
            const inputs = gsap.utils.toArray<HTMLInputElement>("input");

            formRef.current.resetForm();
            inputs.forEach((input, index) => {
                formRef.current.setFieldValue(input.name, FITB_VALUES[index]);
            });

            formRef.current.validateForm();
        }, mainRef); // <- IMPORTANT! Sc
    };

    return (
        <div
            ref={mainRef}
            css={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <div
                role="button"
                onClick={() => {
                    solve();
                }}
                className="solve-button"
                css={{
                    cursor: "pointer",
                    opacity: 0,
                    position: "fixed",
                    bottom: "5vh",
                    left: "2vw",
                    zIndex: 202
                }}
            >
                <Text className="gj-menu-item" color="white" variant="mainNav">
                    Solve this for me
                </Text>
            </div>
            <div css={{ textAlign: "center" }}>
                <Text
                    id="fitb-container"
                    css={{ marginBottom: "10vh" }}
                    variant="caption"
                    color="white"
                >
                    Fill in the blanks to open the map
                    <br />
                    <div css={{ marginTop: "1rem" }}>
                        *HINT: ACCIO GULAB JAMUN
                    </div>
                </Text>
                <div
                    id="fitb-blanks"
                    css={{
                        span: {
                            visibility: "hidden"
                        }
                    }}
                >
                    <Formik
                        innerRef={formRef}
                        initialValues={{
                            fitb1: "",
                            fitb2: "",
                            fitb3: "",
                            fitb4: "",
                            fitb5: "",
                            fitb6: "",
                            fitb7: "",
                            fitb8: "",
                            fitb9: "",
                            fitb10: "",
                            fitb11: ""
                        }}
                        isInitialValid={false}
                        validationSchema={FitbSchema}
                        onSubmit={() => {}}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid: _isValid
                            /* and other goodies */
                        }) => {
                            // if(_isValid) {
                            //     onComplete && onComplete();
                            // }
                            setIsValid(
                                JSON.stringify(values).toLowerCase() ===
                                    JSON.stringify({
                                        fitb1: "S",
                                        fitb2: "W",
                                        fitb3: "T",
                                        fitb4: "W",
                                        fitb5: "H",
                                        fitb6: "R",
                                        fitb7: "F",
                                        fitb8: "N",
                                        fitb9: "T",
                                        fitb10: "H",
                                        fitb11: "M"
                                    }).toLowerCase()
                            );
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Text variant="fitb" color="#FFF">
                                        <span>F</span>
                                        <span>a</span>
                                        <span>n</span>
                                        <span>t</span>
                                        <span>a</span>
                                        <span>s</span>
                                        <span>t</span>
                                        <span>i</span>
                                        <span>c</span>
                                        <span> </span>
                                        <span>
                                            <SingleInput
                                                ref={fitb1Ref}
                                                nextElRef={fitb2Ref}
                                                index={0}
                                                onChange={handleChange}
                                                hasError={
                                                    values.fitb1 && errors.fitb1
                                                }
                                                hasErrorImmediate={errors.fitb1}
                                                hasSuccess={
                                                    values.fitb1.length &&
                                                    !errors.fitb1
                                                }
                                                value={values.fitb1}
                                            />
                                        </span>
                                        <span>
                                            <SingleInput
                                                ref={fitb2Ref}
                                                nextElRef={fitb3Ref}
                                                index={1}
                                                onChange={handleChange}
                                                hasError={
                                                    values.fitb2 && errors.fitb2
                                                }
                                                hasErrorImmediate={errors.fitb2}
                                                hasSuccess={
                                                    values.fitb2.length &&
                                                    !errors.fitb2
                                                }
                                                value={values.fitb2}
                                            />
                                        </span>
                                        <span>E</span>
                                        <span>E</span>
                                        <span>
                                            <SingleInput
                                                ref={fitb3Ref}
                                                nextElRef={fitb4Ref}
                                                index={2}
                                                onChange={handleChange}
                                                hasError={
                                                    values.fitb3 && errors.fitb3
                                                }
                                                hasErrorImmediate={errors.fitb3}
                                                hasSuccess={
                                                    values.fitb3.length &&
                                                    !errors.fitb3
                                                }
                                                value={values.fitb3}
                                            />
                                        </span>
                                        <span>S</span> <br />
                                        <span> </span>
                                        <span>A</span>
                                        <span>N</span>
                                        <span>D</span>
                                        <span> </span>
                                        <span>
                                            <SingleInput
                                                ref={fitb4Ref}
                                                nextElRef={fitb5Ref}
                                                index={3}
                                                onChange={handleChange}
                                                hasError={
                                                    values.fitb4 && errors.fitb4
                                                }
                                                hasErrorImmediate={errors.fitb4}
                                                hasSuccess={
                                                    values.fitb4.length &&
                                                    !errors.fitb4
                                                }
                                                value={values.fitb4}
                                            />
                                        </span>
                                        <span>
                                            <SingleInput
                                                ref={fitb5Ref}
                                                nextElRef={fitb6Ref}
                                                index={4}
                                                onChange={handleChange}
                                                hasError={
                                                    values.fitb5 && errors.fitb5
                                                }
                                                hasErrorImmediate={errors.fitb5}
                                                hasSuccess={
                                                    values.fitb5.length &&
                                                    !errors.fitb5
                                                }
                                                value={values.fitb5}
                                            />
                                        </span>
                                        <span>E</span>
                                        <span>
                                            <SingleInput
                                                ref={fitb6Ref}
                                                nextElRef={fitb7Ref}
                                                index={5}
                                                onChange={handleChange}
                                                hasError={
                                                    values.fitb6 && errors.fitb6
                                                }
                                                hasErrorImmediate={errors.fitb6}
                                                hasSuccess={
                                                    values.fitb6.length &&
                                                    !errors.fitb6
                                                }
                                                value={values.fitb6}
                                            />
                                        </span>
                                        {/* <span>E TO</span>  */}
                                        <span>E</span>
                                        <span> </span>
                                        <span>T</span>
                                        <span>O</span>
                                        <br />
                                        <span>
                                            <SingleInput
                                                ref={fitb7Ref}
                                                nextElRef={fitb8Ref}
                                                index={6}
                                                onChange={handleChange}
                                                hasError={
                                                    values.fitb7 && errors.fitb7
                                                }
                                                hasErrorImmediate={errors.fitb7}
                                                hasSuccess={
                                                    values.fitb7.length &&
                                                    !errors.fitb7
                                                }
                                                value={values.fitb7}
                                            />
                                        </span>
                                        <span>I</span>
                                        <span>
                                            <SingleInput
                                                ref={fitb8Ref}
                                                nextElRef={fitb9Ref}
                                                index={7}
                                                onChange={handleChange}
                                                hasError={
                                                    values.fitb8 && errors.fitb8
                                                }
                                                hasErrorImmediate={errors.fitb8}
                                                hasSuccess={
                                                    values.fitb8.length &&
                                                    !errors.fitb8
                                                }
                                                value={values.fitb8}
                                            />
                                        </span>
                                        <span>D </span>
                                        <span>
                                            <SingleInput
                                                ref={fitb9Ref}
                                                nextElRef={fitb10Ref}
                                                index={8}
                                                onChange={handleChange}
                                                hasError={
                                                    values.fitb9 && errors.fitb9
                                                }
                                                hasErrorImmediate={errors.fitb9}
                                                hasSuccess={
                                                    values.fitb9.length &&
                                                    !errors.fitb9
                                                }
                                                value={values.fitb9}
                                            />
                                        </span>
                                        <span>
                                            <SingleInput
                                                ref={fitb10Ref}
                                                nextElRef={fitb11Ref}
                                                index={9}
                                                onChange={handleChange}
                                                hasError={
                                                    values.fitb10 &&
                                                    errors.fitb10
                                                }
                                                hasErrorImmediate={
                                                    errors.fitb10
                                                }
                                                hasSuccess={
                                                    values.fitb10.length &&
                                                    !errors.fitb10
                                                }
                                                value={values.fitb10}
                                            />
                                        </span>
                                        <span>E</span>
                                        <span>
                                            <SingleInput
                                                ref={fitb10Ref}
                                                index={10}
                                                onChange={handleChange}
                                                hasError={
                                                    values.fitb11 &&
                                                    errors.fitb11
                                                }
                                                hasErrorImmediate={
                                                    errors.fitb11
                                                }
                                                hasSuccess={
                                                    values.fitb11.length &&
                                                    !errors.fitb11
                                                }
                                                value={values.fitb11}
                                            />
                                        </span>
                                    </Text>
                                </form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};
